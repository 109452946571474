@font-face {
    font-family: 'Quicksand';
    src: url(/public/assets/fonts/guyum/Quicksand-Regular.ttf);
}
.app-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    flex-direction: column;
    
}
.logo-container{
    width: 100%;
    height: 150px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo-container svg{
    height: 100%;
    transform: scale(1);
}
svg{
    z-index: 9999;
}
.MuiGrid-root svg path{
    fill: #fff;
}
.address-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 32px;
    position: absolute;
    align-items: center;
    justify-content: flex-start;
}
.address-text{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 19px;
    font-size: 12px;
    font-family: 'Quicksand' !important;
    color: #5D5D5D;
}
.send-badge{
    font-family: 'Quicksand' !important;
    text-decoration: underline;
    text-decoration-color: #1954D9;
    color: #1954D9;
    display: flex;
    align-items: flex-end;
    justify-content: center;

}
.send-badge span{
    font-family: 'Quicksand' !important;
    font-size: 10px;
    margin-left: 5px;
    transform: translateY(2px);
}