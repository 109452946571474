@font-face{
    font-family:'OoredooArabic';
    src:url('../../assets/fonts/OoredooArabicRegular.ttf');
}
@font-face{
    font-family:'OoredooArabicHeavy';
    src:url('../../assets/fonts/OoredooArabic-Heavy.ttf');
}
@font-face {
    font-family: "SF pro Bold";
    src: url('../../assets/fonts/FontsFree-Net-SFProText-Bold.ttf');
}
@font-face{
    font-family:'Tajawal-Bold'; 
    src:url('../../assets/fonts/Tajawal-Bold.ttf');
}

@font-face{
    font-family:'Tajawal-Regular';
    src:url('../../assets/fonts/Tajawal-Regular.ttf');
}
@font-face{
    font-family:'Tajawal-ExtraBold';
    src:url('../../assets/fonts/Tajawal-ExtraBold.ttf');
}
@font-face{
    font-family:'Arialnb';
    src:url('../../assets/fonts/Arialnb.ttf');
}
@font-face{
    font-family:'ARIAL-Narrow';
    src:url('../../assets/fonts/ARIALN.TTF');
}
.jobsU{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 28px !important;
    padding-left: 28px !important;

}
.container {
    width: 100%;
    min-height: 100vh;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.con
.modal-body{
    padding:0px !important;
    
}
.jobsU.rll{
    align-items: flex-start; 
}
.rll .oppor{
    line-height: 30px;
    font-size: 23px;
}
.rll .job_word{

}
.modal-body{
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #FFFFFF29;
    border-radius: 20px;
    margin-top: 10px;
}
#labek{
    position: absolute;
    width: 100%;
    top: 0px;
    height: 100%;
    left: 0px;
}

.modal-content{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #FFFFFF29;
    border-radius: 20px;
    padding:20px;
}
.svgEl{
    width: 20px;
    height: 15px;
    background-color: #000;
}
.Rspan {
    position: absolute;
    border-radius: 50%;
    /* To make it round */
    background-color: rgba(100, 100, 100, 0.3);

    width: 100px;
    height: 100px;
    margin-top: -50px;
    /* for positioning */
    margin-left: -50px;

 
    opacity: 0;
    display: none;
}
.ripple{
    animation: ripple 1s;
}
@keyframes ripple {
    from {
        opacity: 1;
        transform: scale(0);
    }

    to {
        opacity: 0;
        transform: scale(10);
    }
}
.add_bu, .done_bu{
    position: relative;
    overflow:hidden;
}
.divModal{
    min-height: 97px;
    border: 0.5px solid #707070;
    padding: 20px;
    overflow:hidden;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}
.modal.fade.show{
    backdrop-filter: blur(10px);
}
.ls5 .join_{
    letter-spacing: 3px;
}
.rll .join_{
    letter-spacing: 4px;
    margin-left: 4px;
    line-height: 23px;
}
.oppor{
    font-family: "Tajawal-Regular";
    color: #5A5A5A;
    font-size: 24.5px;
    line-height: 20px;
}
.job_word{
    font-family: "Tajawal-ExtraBold";
    color: #5A5A5A;
    font-weight: bolder;
    /* line-height: 0px; */
    line-height: 42px;
    font-size: 52.5px;
}
.join_{
  
    font-family: "Tajawal-Regular";
    color: #5A5A5A;
    font-size: 18px;
    /* line-height: 0px; */
    line-height: 40px;
}
.firsec, .secsec{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
*{ 
    font-family:'OoredooArabic';
}
.jss19 {
    margin: auto;
    text-align: center;
}
.jss32 {
    width: 100%;
}
.jss28 {
    padding-right: 0px !important;
    background-color: rgb(94, 94, 93);
}
.jss29 {
    color: whitesmoke;
    height: 170px;
    display: flex;
    box-shadow: 0 -9px 12px 7px rgb(0 0 0 / 50%);
    padding-left: 33px;
    padding-right: 23px;
    background-color: #ff6679;
}
@media  (max-width: 1280px){

.jss38 {
    margin-left: -4px;
}}
@media (max-height: 749px)
{.Layout_main__1K1zs {
    flex: 1 1;
    height: 700px;
}}
.Layout_main__1K1zs {
    flex: 1 1;
}.Layout_content__2Z1lZ {
    height: 700px;
}

.Layout_content__2Z1lZ {
    background-color: #fff;
}
@media (max-height: 749px)
{.Layout_footer__JcUV6 {
    margin-top: auto;
    position: static;
    bottom: 0;
    width: 100%;
}}
.Layout_footer__JcUV6 {
    height: 30px;
}
@media (max-width: 960px)
{.Footer_footer__1iYp- {
    height: 130px;
}}
.Footer_footer__1iYp- {
    background-color: #fff;
    color: #000;
    height: 94px;
    justify-content: center;
}
@media (max-width: 960px)
{.Footer_top_footer__3umen {
    padding-top: 0;
}}
.Footer_top_footer__3umen {
    padding-top: 16px;
    height: 62px;
    font-size: 10px;
    margin: auto!important;
    background-color: #fff;
    color: #000;
    text-align: center;
    font-family: roboto;
}
@media (max-width: 960px)
{.Footer_footer_text__1lPn4 {
    font-size: 16px;
}}
.Footer_footer_text__1lPn4 {
    text-align: center;
}.MuiAppBar-colorPrimary {
    color: #fff;
    background-color: #3f51b5;
}


.MuiAppBar-positionFixed {
    top: 0;
    left: auto;
    right: 0;
    position: fixed;
}

.MuiAppBar-root {
    width: 100%;
    display: flex;
    z-index: 1100;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-direction: column;
}

.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.MuiPaper-root {
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
}
.MuiGrid-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}
@media (min-width: 600px)

{.MuiGrid-grid-sm-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
}}


.MuiGrid-grid-xs-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
}
.MuiGrid-item {
    margin: 0;
    box-sizing: border-box;
}
.MuiGrid-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}
.MuiContainer-root {
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
}
@media (min-width: 600px)

{.MuiGrid-grid-sm-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
}}


.MuiGrid-grid-xs-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
}

.MuiGrid-item {
    margin: 0;
    box-sizing: border-box;
}
.jss1 {
    position: relative;
}
.jss32 {
    width: 100%;
}
.jss19 {
    margin: auto;
    text-align: center;
}
@media (min-width: 960px)

{.MuiGrid-grid-md-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
}}

@media (min-width: 600px)

{.MuiGrid-grid-sm-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
}}

.MuiGrid-grid-xs-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
}
@media (min-width: 960px)

{.MuiGrid-grid-md-4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
}}

@media (min-width: 600px)

{.MuiGrid-grid-sm-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
}}

.MuiGrid-grid-xs-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
}
.Footer_footer_text__1lPn4 {
    text-align: center;
}
.MuiGrid-grid-xs-12 {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    max-width: 100%;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
}

@media (min-width: 600px)
{
.MuiGrid-grid-sm-12 {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    max-width: 100%;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
}}
@media (min-width: 960px)

{.MuiGrid-grid-md-4 {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    max-width: 33.333333%;
    -webkit-flex-basis: 33.333333%;
    flex-basis: 33.333333%;
}}

.MuiGrid-grid-xs-12 {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    max-width: 100%;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
}
@media (min-width: 600px)

{.MuiGrid-grid-sm-12 {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    max-width: 100%;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
}}

.MuiGrid-item {
    margin: 0;
    box-sizing: border-box;
}

.MuiGrid-grid-xs-12 {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    max-width: 100%;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
}
@media (min-width: 600px)

{.MuiGrid-grid-sm-12 {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    max-width: 100%;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
}}

.MuiGrid-item {
    margin: 0;
    box-sizing: border-box;
}
.ll{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (min-width: 960px)

{.MuiGrid-grid-md-2 {
    flex-grow: 0;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
}}
@media (min-width: 960px)

{.MuiGrid-grid-md-8 {
    flex-grow: 0;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
}}
@media (min-width: 960px)

{.MuiGrid-grid-md-4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
}}
.mcol{
    padding: 20px;
    font-size: 14px;
    display: flex;
    color: #5a5a5a;
    text-align: center;
    font-family: 'OoredooArabic';
    justify-content: center;
}
.mcol2{
    padding: 50px;
    font-size: 14px;
    display: flex;
    color: #5a5a5a;
    text-align: center;
    font-family: 'OoredooArabic';
    justify-content: center;
}
.mcol22{
    padding-top: 0px;
    padding-right: 50px;
    padding-left: 50px;
    font-size: 14px;
    display: flex;
    color: #5a5a5a;
    text-align: center;
    font-family: 'OoredooArabic';
    justify-content: center;
}
.mcol3{
    padding-right: 5px !important;
    padding-left: 5px !important;
    padding-top:15px ;
    font-size: 14px;
    display: -webkit-flex;
    display: flex;
    color: #5a5a5a;
    text-align: end;
    flex-direction: row-reverse;
    font-family: 'OoredooArabic';
    -webkit-justify-content: flex-end;
    justify-content: flex-start;
}
.mcol3.klr{
    flex-direction: row;
}
.klr .part{
    flex-direction: row;
}
.sp{
    max-width: 540px;
    text-align: center;
    display: flex;
    text-align-last: center;
    text-align: -webkit-center;
    justify-content: center;
    align-items: center; 
    text-anchor: middle;
    align-content: center;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: normal;
}
.jss292{
    padding: 0px 15px;
    color: whitesmoke;
    height: 111px;
    display: -webkit-flex;
    display: flex;
   
    padding-left: 33px;
    padding-right: 23px;

    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;

}
.jss322{
  
    width: 100%;
}
.par{
    background-color: #F8F8F8;
}
/* .jss382 {
    margin-left: -4px;
    width: 155px;
    padding: 8px 10px 55px 10px;
} */
.jss382 {
    margin-left: -4px;
    width: 155px;
    /* padding: 8px 10px 55px 10px; */
    transform: scale(1.8) translateY(-6px);
}
.loc{
    padding-right: 20px;
    padding-left: 20px;
}
.part{
    display: -webkit-flex;
    display: flex;
    color: #5a5a5a;
    text-align: end;
    flex-direction: row-reverse;
    font-family: 'OoredooArabic';
    -webkit-justify-content: flex-end;
    justify-content: flex-start;
    max-width: 540px;
}
.rw{
    justify-content: flex-end; 
    padding-right: 23px;
    padding-left: 23px;
    margin-top: 10px;
}
.rw.rwend{
    justify-content: flex-start;
}
.roundf{
    background-color: rgb(90 90 90 / 100%) !important;
}
*{
    
    text-transform: capitalize;
}
.roundInf{
    min-height: 77px;
    width: 100%;
    font-size: 14px;
    background-color: rgb(90 90 90);
    
    text-transform: capitalize;
    box-shadow: 0px 3px 6px #00000029;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 50px;
    letter-spacing: 0px !important;
    border-top-right-radius: 50px;
}
input.btn{
    user-select: text !important;
    -moz-user-select: text !important;
    user-select: text!important;
    -moz-user-select: text!important;
    -webkit-user-select: text !important;
}

.inp{
    width: 100%;
}
.con *{
    font-family: 'ARIAL-Narrow' !important;
}
.con * *{
    font-family: 'ARIAL-Narrow' !important;
}
.con * * * {
    font-family: 'ARIAL-Narrow' !important;
}
.inpu{
    color: #5a5a5a;
    background-color: #F7F5F5;
    border-radius: 20px;
    border: 0.5px solid #70707094 !important;
    text-align: center;
    width: 100%;
    height: 60px;
    cursor: text;
    z-index: 1;
    transition-property: all;
    /* transition-delay: 3s; */
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
}
.inpu:valid, .inpu:valid:disabled{
    background-color: #D2FCE8;
}
.inpui.val{
    background-color: #D2FCE8;  
}
.mcol3i{
    padding-right: 40px;
    padding-top:15px ;
    font-size: 14px;
    display: -webkit-flex;
    display: flex;
    color: #5a5a5a;
    text-align: end;
    flex-direction: row-reverse;
    font-family: 'OoredooArabic';
    -webkit-justify-content: flex-end;
    justify-content: flex-start;
}
.inpu:focus{
    outline: none;
}
.roundInfd{
    min-height: 77px;
    width: 100%;
    background-color: rgb(90 90 90 / 75%);

    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #436EFF;
    font-size: 20px;
    cursor: pointer;
}
.svgic{
    position: absolute;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    height: 60px;
    z-index: 0;
    display: flex;
    background-color: transparent;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    padding-left: 25px;
}
*{
    font-weight: normal !important;
}
.inpui{
    color: #757575 !important;
    background-color: #f7f5f5;
    border-radius: 20px;
    border: #5a5a5a67 0.5px solid;
    text-align: center;
    width: 100%;
    height: 60px;
    z-index: 1;
    transition-property: all;
    /* transition-delay: 3s; */
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
}
.inpuix{
    color: #5A5A5A;
    background-color: #f7f5f5;
   border-top-right-radius: 20px;
   border-top-left-radius: 20px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border: #5a5a5a67 0.5px solid;
    text-align: center;
    width: 100%;
    height: 46px;
    z-index: 1;
    transition-property: all;
    /* transition-delay: 3s; */
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
}
input:focus{
    outline: none;
}
button:focus{
    outline: none;
}
.opt{
    color: #F7F5F5;
    background-color: #5D5D5D;
    border: #5D5D5D 0.5px solid;
    text-align: center;
    border-bottom: #f7f5f5c2 0.5px solid;
    
    text-transform: capitalize;
    height: 46px;
    width: 100%;
}
.btn{
    -webkit-user-select: all !important;
    user-select: all !important;
}
.endbt{
    color: #5a5a5a;
    background-color: #F7F5F5;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
     border-bottom-left-radius: 20px;
     border-bottom-right-radius: 20px;
    border: #5a5a5a67 0.5px solid;
    text-align: center;
    width: 100%;
    height: 46px;
    z-index: 1;
    
}
.btn.focus, .btn:focus {
    outline: 0;

}


.btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #ff626c;
    border-color: #ff626c;
}
.btn-success{
    color: #fff;
    background-color: #ff626c;
    border-color: #ff626c;
}
.btn-success:hover{
    color: #fff;
    background-color: #ff626c;
    border-color: #ff626c;
}
.modal-footer {
    display: -webkit-flex;
    background-color: #5a5a5a;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
    border-bottom-right-radius: .3rem;
    border-bottom-left-radius: .3rem;
}
.modal-body {
    position: relative;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    border: 0px;
    height: 100%;
    padding: 1rem;
    background-color: #5a5a5a;
}
.modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
    background-color: #ff626c;
}
input:invalid{
    border: rgba(255, 0, 0, 0.308) 0.5px solid;
}
.iof{
width:100%;
height: 100%;
display: flex;
justify-content: flex-end;
align-items: center;
}
.eof{
    width:50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.imgo{
    height: 80%;
    width: 60px;
}
.lang_btn{
    border: #70707086 0.5px solid;
    color: #FF6464;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
    padding: 10px;
    cursor: pointer;
    height: 33px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-around;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: row;
    flex-direction: row;
    border-radius: 5px !important;
    -webkit-align-content: center;
    align-content: center;
}
.fxd{
    flex-direction: row-reverse !important;
    display: flex !important;
}
.imgc{
   display: flex;
   width:50%;
   justify-content: flex-end;
}
.fix{
    position: absolute;
    left: 5px;
    z-index: 1;
    top: 140px;
}
.fix.rl{
    left: initial;
    right: 5px;
}
.menus{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.MuiGrid-root.MuiGrid-container{
    position: relative !important;
}
.item_men{
    font-size: 14px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100px;
    color: #1d2124;
    cursor: pointer;
    margin-top: 10px;

}
.item_men.acti{
    color: #6173de;
}
#root{
  position: relative !important;
}
li::marker{
    display: none;
    color: white;
}
.prog_con{
    min-width: 100%;
    border-radius: 20px;
    margin-top: 20px;
    border: 0.5px solid #707070;
    min-height: 7px;
}
.prog_child{
    border-radius: 20px;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
    background: #82F2B6 0% 0% no-repeat padding-box;
    border-right: 0.5px solid #707070;
    min-height: 7px;
}
.image_sec{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
}
.image_elem{
    border: 0.5px solid #707070;
    border-radius: 10px;
    width: 46px;
height: 59px;
    margin-left: 10px;
}
.imgs{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}
.add_paren{
    width: 46px;
height: 59px;
}
.add_bu{
    width: 100%;
height: 100%;
position: relative;
/* UI Properties */
background: #C8FDE1 0% 0% no-repeat padding-box;
border: 0.5px solid #707070;
border-radius: 10px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.modal-content {
    background-color:transparent !important ; 
    box-shadow: none !important;
    border: none !important;
    border-radius: 20px;
    padding: 20px;
}
.dis{
    background: #83978c 0% 0% no-repeat padding-box !important; 
    color: #fff !important;
}
.done_bu{
    height: 40px;
    cursor: pointer;

/* UI Properties */
background: #82F2B6 0% 0% no-repeat padding-box;
border: 0.5px solid #707070;
transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
border-radius: 10px;
opacity: 1;
font-size: 19px;
width: 100%;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
color:#5A5A5A;
margin-top: 11px;
}
.uplo{
    font-size: 14px;
    text-align: center;
    color: #5A5A5A;
}
.rc-anchor-normal .rc-anchor-checkbox-label {
    width: 152px;
    font-size: 10px !important;
}
.recaptcha-checkbox-border {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background-color: #fff;
    border: 2px solid #c1c1c1;
    font-size: 1px;
    height: 24px;
    position: absolute;
    width: 24px;
    z-index: 1;
    height: 15px !important;
    width: 15px !important;
}
.recaptcha-checkbox {
    border: none;
    font-size: 1px;
    height: 28px;
    display: flex !important;
    margin: 4px;
    width: 28px;
    overflow: visible;
    outline: 0;
    vertical-align: text-bottom;
    align-items: center !important;
    justify-content: center !important;
}
.finale{
display:flex;
justify-content: center;
align-items: center;
padding-left: 68px;
padding-right: 68px;
flex-direction: column;
}
.req{
    /* UI Properties */
text-align: center;
font: normal normal normal 18px/21px "ARIAL-Narrow";
color: #5A5A5A;
text-transform: capitalize;
opacity: 1;
margin-top: 25px;
}
.rev{
    text-align: center;
font: normal normal normal 16px/30px "ARIAL-Narrow";
color: #5A5A5A;
text-transform: capitalize;
opacity: 1;
margin-top: 100px;
}

.inp{
position: relative;
}
.inp .inpu:invalid~ svg, .inp .inpu:disabled~ svg{
    position: absolute;
    display:none
}
.inp .inpu:valid~ svg, .inp .inpu:valid:disabled~ svg{
    position: absolute;
    display:block;
    top:23px ;
    z-index: 9;
    left: 20px;

}
.modal-content {
    position: initial !important;
}
label{
    cursor:pointer;
}
.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
    position: relative !important;
}
.modal-body{
    position: relative;
}
.svg_abs{
    bottom: 175px;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.divMod{
    display: -webkit-flex;
    width: 100%;
    display: flex;
    -webkit-align-items: center;
    align-items: center;

    -webkit-justify-content: center;
    justify-content: space-between;
    -webkit-flex-direction: row;
    flex-direction: row;

}
.inp *{
    font-size: 14px !important;
    font-size: 14px !important;
    letter-spacing: 1px;
}
.inp .svgic svg {
    font-family: 'FontAwesome' !important;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 17px !important;
    cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield !important;
}
input:focus{
    font-size: 14px !important;
}
input{ touch-action: none; }
/* Accoun section*//* Accoun section*/
/* Accoun section*//* Accoun section*/
/* Accoun section*//* Accoun section*/
/* Accoun section*//* Accoun section*/
/* Accoun section*//* Accoun section*/
/* Accoun section*//* Accoun section*/
/* Accoun section*//* Accoun section*/
/* Accoun section*//* Accoun section*/
/* Accoun section*//* Accoun section*/
/* Accoun section*//* Accoun section*/
/* Accoun section*//* Accoun section*/
/* Accoun section*//* Accoun section*/
/* Accoun section*//* Accoun section*/
/* Accoun section*//* Accoun section*/
/* Accoun section*//* Accoun section*/
/* Accoun section*//* Accoun section*/
.Home_con.in{
background-color: #FEEFD3;
}
.Home_con.ou{
    background-color: #D3FFD3;
}
.Home_con{
    height: 100vh;
    overflow: hidden;
}
.logo_sec{
    display: flex;
        z-index:800;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    width: 100%;
}
.ac_y{
    margin-top: 30px;
    width: 100%;
    height: 100vh;
    display: flex;
    z-index:800;
    justify-content: center;
    align-items: flex-start;
}
.ac_con{
    position: relative;
    height: 42px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
}
.in_yp, .ou_yp{
position: absolute;
transition-property: all;
/* transition-delay: 3s; */
transition-duration: 0.3s;
transition-timing-function: ease-in-out;
opacity: 1;
}
.inp_con{

    margin-top: 5px;
    width: 100%;
    z-index:8000;
    position: fixed;
    bottom: 0px;
    left: 0px;
}
#cons{
    padding:0px !important;
}
.inp_rel{
    position: relative;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    width: 100%;
}
.inp_rel input{
    outline: none !important;
    box-shadow: none !important;
    border:none !important;
    height: 67px;
    width: 100%;
    transition-property: all;
    /* transition-delay: 3s; */
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    border-radius: 19px;
    color:#969696;
    font-size: 25px !important;
    text-align: center;
    font-family: "SF pro";
}
.inp_rel input:focus{
    font-size: 25px !important;
}
.inp_rel input::placeholder{
    color:#969696;
}
text tspan{
    font-family: "Tajawal-Regular";
}
.inp_rel input.in{
    background-color: #D8F6D9;
}
.inp_rel input.ou{
    background-color: #F6EBD8;
}
.opa1{
    opacity: 0 !important;
}
#cons{
    user-select: none;
    transition-property: all;
    /* transition-delay: 3s; */
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
}
.svg_currency{
    height: 100%;
    position: absolute;
    right: 10px;
    top: 0px;
    display: flex;
    justify-content:center;


    align-items: center;
}
.account_options{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 5px;
}
.ac_option{
    color:#EDEEF0;
    font-size: 16px;
    font-family: "Tajawal-Regular";
    background-color: #424E59;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 6px;
    border-radius: 15px;
    min-height: 30px;
    padding: 8px 40px;
}
.jc{
    position: absolute; 
    left: 13px;
    width: 67px;
    z-index: 9000;
    height: 67px;
    top: 0px;
}
.joystickContainer{
    position: relative;
    top:0px;
    width: 35px;
    height: 67px;
    border-radius: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.abs_joy{
    position: absolute;
}
.active_na{
    transform: scale(2);
    max-width: 20px;
    max-height: 20px;
}
.na{
    transition-property: all;
    /* transition-delay: 3s; */
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
}
.na.op1{
    max-width: 10px;
    max-height: 10px;
    position: absolute;
    top: 28.5px; 
    left:4px;
}
.na.op4{
    max-width: 10px;
    max-height: 10px;
    position: absolute;
    top: 28.5px;
    left: 8px;
}
.na.op5{
    max-width: 10px;
    max-height: 10px;
    position: absolute;
    top: 28.5px;
    right: -18px;
}
.horAli.spac{
    justify-content: space-around !important;
    flex-direction: row-reverse !important;
}
.na.op2{
    max-width: 10px;
    max-height: 10px;
    position: absolute;
    top: 7px; 
    left:25px;
    margin-top: 2px;
}
.na.op3{
    max-width: 10px;
    max-height: 10px;
    position: absolute;
    bottom: 7px; 
    left:25px;
}
svg.active_na.trueC  *{
fill: #424E59;
opacity: 1;
}
svg.active_na  *{
    opacity: 1;
    }
svg.na.trueC *{
    fill:#969696;
    opacity: 0.75;
}
svg.na *{
    opacity: 0.75;
}
.big svg.na.trueC *{
   fill: rgb(233, 233, 233) !important;
}
.active_na{
    transform: scale(2);
}
.active_na.trueC *{
    fill:#424E59;
}
.dr{
    border-radius: 50%;
    width: 49px;
    opacity: 0;
    height: 48px;
    z-index: 99999;
    top: 10px;
    left: 5px;
    max-width: 500px;
    max-height: 500px;
    position: absolute;
    background-color: #96969663;
    /* display: -webkit-flex; */
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
}
.ddr{
    border-radius: 50%;
    width: 30px;
    max-height: 280px;
    max-width: 280px;
    opacity: 0;
    height: 30px;
    background-color: #424E59;
}
.nas_con{
    border-radius: 50%;
    width:100%;
    height:100%;
    max-height: 280px;
    overflow: hidden;
    padding: 1em;
    max-width: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;  
}
.ddr.big svg{
    max-height: 170px;
    max-width: 170px; 
}
.dr, .ddr{
 
    /* transition-delay: 3s; */
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease-in;
}
.fo_na.trueC{
    z-index: 9999999;
    fill: #fff !important;
    opacity: 1 !important;
    z-index: 9999999;
    fill: #96969696 !important;
}
.fo_na{
    z-index: 9999999;
    opacity: 1 !important;

}
.fo_na.trueC *{
    fill: #e5e5e5 !important;
    opacity: 1 !important;
}
.fo_na *{
 
    opacity: 1 !important;
}
.nas{
    transition-property: all;
    opacity: 0;
    top: 15%;
    left: 18%;
    width: 20vw;
    height: 20vw;
    max-width: 280px; 
    max-height: 280px;
    /* transition-delay: 3s; */
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
}
#money-7{
    position: absolute;
    bottom: 7px;
    right: -18px;
}
.logo_sec .cen{
    position: relative;
}
.big{
    opacity: 1 !important;
    
}
.fix-wi{
    width: 100%;
    max-width: 100% !important;
}
.big.ddr{
    width: 30vw !important;
    position: relative;
    height: 30vw !important;
    opacity: 1 !important;

}
.big.dr{
    opacity: 1 !important;
    width: 50vw !important;
    height: 50vw !important;
}
.sade_dr{
    border-width:clamp(1vw, 25vw, 250px) !important;
    opacity: 1 !important;
    width: 3vw !important;
    height: 3vw !important;
    border-bottom:clamp(1vw, 25vw, 250px) solid #5A5A5A;
    border-top:clamp(1vw, 25vw, 250px) solid #5A5A5A;
    border-right:clamp(1vw, 25vw, 250px) solid #5a5a5a05;
    border-left:clamp(1vw, 25vw, 250px) solid #5a5a5a1a;
    border-radius: 50%;
    border-radius: 50%;
    transition-property: all;
    /* transition-delay: 3s; */
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    z-index: 99999;
    top: 10px;
    left: 5px;
    max-width: 500px;
    max-height: 500px;
    position: absolute;
    background-color: #96969663;
    /* display: -webkit-flex; */
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
}
.sade_dr.lef{
    border-bottom:clamp(1vw, 25vw, 250px) solid #5a5a5a00;
    border-top:clamp(1vw, 25vw, 250px) solid #5a5a5a00;
    border-right:clamp(1vw, 25vw, 250px) solid #5a5a5a;
    border-left :clamp(1vw, 25vw, 250px) solid #5a5a5a;
}
.big.jc{
   position:fixed !important;
    top:15% !important;
    left:25% !important;
}
.dis{
    display: none;
}
.big .joystickContainer{
    width: 50vw;
    height: 50vw;
}
.horAli, .verAli{
    display: none;
}
.horAli svg.trueC *, .verAli svg.trueC *{
    fill:rgb(233, 233, 233)
}
.big .horAli{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    top: 0px;
    left: 0px;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}
.big .verAli{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    top: 0px;
    left: 0px;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
}
.verAli svg, .horAli svg{
    width: 10vw;
    position: initial !important;
    height: 10vw;
    max-width: 100px !important;
    max-height: 100px !important;
}
.big .joystickContainer .active_na{
    display: none;
}
.Home_con .sh{
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    position: absolute;
    top: 0px;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
    opacity: 0;
    z-index: -100;
    background-color: rgba(0, 0, 0, 0.13);
    left: 0px;
    backdrop-filter: blur(4px);
    background-color: rgb(0 0 0 / 26%);
}
.bn{
    opacity: 0;
    z-index: 8000 !important;
}

.keyboard_container{
    display: -webkit-flex;
    padding-left: 19px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    margin-bottom: -283px;
    width: 100%;
    transition-property: all;
    /* transition-delay: 3s; */
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
    -webkit-flex-direction: column;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.60);
    padding-top: 6px;
    padding-right: 7.4px;

}
.anim_in{
    animation-name: in;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    transform: translateX(100vw);
}
.anim_ou{
    animation-name: ou;
    animation-duration: 0.8s;
    animation-iteration-count: 1;
    transform: translateX(0);
}
@keyframes in {
    0%   {transform: translateX(100vw);}
    100% {background-color: translateX(0px);}
  }
  @keyframes ou {
    0%   {transform: translateX(0);}
    100% {background-color: translateX(-100vw);}
  }
  .num_con{
  justify-content: space-between !important;
  }
  .num_con .um_but{
    border-radius: 5px;
    width: 100%;

    height: 46px;
    display: flex;
    flex-direction: column;
    line-height: 7px;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    padding-bottom: 5px;
    font-size: 25px;
    background-color: hsla(0, 0%, 100%, 0.404);
    font-family: 'SF pro Bold' !important;
    box-shadow:inset #00000017 0px 3px 6px, #000000 0px 1px 0px;
  }
  .um_but span{
     text-align: center;
     font-family: 'SF pro Bold' !important;
    width: 100%;
  }
  .lael{
    font-size: 10px;
    transform: translateY(10px);
    font-family: 'SF pro Bold' !important;
  }
  .num_con .col-4{
    padding: 0px;
    padding-bottom: 5px;
    padding-right: 13px;
  }
  .disa{
    opacity: 0;
  }
  .del{
    background-color: hsla(0, 0%, 100%, 0) !important;
    box-shadow:none !important;
    border: none !important;
    border-top: none;
    border-left: none;
    border: none;
    border-bottom: none;
    border-right: none;
  }
  .ce{
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .openKey{
    margin-bottom: 0px;
  }
  .um_but{
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
    border-bottom: rgba(0, 0, 0, 0.205) 1px solid;
    border-top: none;
    border-left: none;
    outline: navajowhite;
    border-right: rgba(0, 0, 0, 0.205) 1px solid;
  }
  .um_but:active{
    border-bottom: none;
    border-top: rgba(0, 0, 0, 0.205) 1px solid;
    border-left: rgba(0, 0, 0, 0.205) 1px solid;
    border-right: none;
  }
  .um_but.del:active{
    transform: scale(0.88);
    border-top: none;
    border-left: none;
    border: none;
    border-bottom: none;
    border-right: none;
  }
  .big .svgCon{
    display: none;
  }
  .opA1{
    opacity: 1 !important;
  }
  .nas_con svg.trueC *{
    fill: #FFF !important;
  }
  .opA1 *{
    opacity: 1 !important;
  }
  .lef.rig{
    border-right:clamp(1vw, 25vw, 250px) solid #141313;
  }
  .lef.lefe{
    border-left:clamp(1vw, 25vw, 250px) solid #141313;
  }
  .sade_dr.vop{
    border-top:clamp(1vw, 25vw, 250px) solid #141313;
  }
  .sade_dr.bo{
    border-bottom:clamp(1vw, 25vw, 250px) solid #141313;
  }