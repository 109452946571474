/* .dropdown-item  div{
    padding:5px 10px 5px 10px ;
}

.dropdown-toggle::after{
    display:none;
}
.dropdown-item:hover{
    background-color: #5d5d5d;
    
    color:white;
}
.dropdown-item:hover #home-2{
    fill:white;
    color:'white';
}
.dropdown-item:hover #tex{
    display:initial;
}
#t:hover{
    background-color: #5d5d5d;
    color:white;
}
#t{
    background-color: white;
    color:black;
}
.dropdown-item #tex{
    display:none;
}
.dropdown-item:hover  div{
    padding:5px 30px 5px 10px ;
}

.dropdown-item #home-2{
color:#5d5d5d;
} */
.x:focus{
    background-color:'#f0f0f0';
    color:'#5d5c5d';
    border:'0.5px solid #dbdbdb';
   
}


.but:hover #tex1{
    transition: background-image 4s 1s;
    background-image: linear-gradient( 
        189deg
         ,#ff7b7b, #ff4545);


}
#t{
 
    margin:0px 0.4%;
}
#l #t{
    margin-left: 0%;

  
}

#l{
    margin-left: 1%;
}

.but:hover svg{
    display: inline-block;
    transition: all .2s ease-in-out; 
   
}


.but:hover .svg1 path{
    fill:#f8eaea;
   
 }
 .but:hover #tex{
    color:#f8eaea;
   
 }
 .but:hover .svg1 rect{
    fill:#f8eaea;
    box-shadow: rgb(0 0 0 / 16%) 0px 2px 4px 0px ;
 }
 .but:hover svg>g>path[class="arr"]{
    fill:#fc4343;
    transition: ease-in-out fill 2S;
 }
#tex1{
    padding :5px 5px 5px 5px;
    border-radius: 12px;
    border:0px solid #ffffff;
    transition: all .2s ease-in-out; 
    width:100%;
    height:100%;
}

.dropdown-item:active{
    background-color: white;
    box-shadow: none;
}
.dropdown-item:hover{
    background-color: white;
    box-shadow: none;
}
.dropdown-item:focus{
    background-color: white;
    box-shadow: none;
}
#tex1{

    display: inline-flex;
    place-content: center center;
    flex-direction: column;
    align-items: center;
   
}
.but{
    display: flex;
    flex-direction: column;
    padding: 4px 1vw 0px 1vw;
}
.but>span>svg{
    display: none;
}
#txa{
   
    width:100%;
}
@media only screen and (max-width: 826px) {
    #txa{
    
     
    }
    #te2x{
        font-size: smaller;
    }
    #tex{
        font-size: smaller;
    }
    #te2xw{
        font-size: x-small;
    }
   
    .x{
        width:100%;
    }
}
#te2x{
    font-size: 12px;
}
#te2xw{
    font-size: 8px;
}
.imgs{
    border-radius: 15px;
    box-shadow: rgb(0 0 0 / 16%) 0px 2px 4px  ;
    border: 0.5px solid #FFFFFF ;
   width:33%;
   height:145px;
    
}
.col-12{
    padding-right: 0px;
    padding-left: 0px;
}
.mh{
    width: 100%;
    min-width:60px;
    height: 13vw;
    max-height: 100%;
  max-height: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  background-attachment: local;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
@media only screen and (max-width: 826px) {
   
    #tc{
        margin-left: 0%;
    }
}
@media only screen and (max-width: 220px) {
    #txa{
       
    }
    .logos svg{
        width: 120%;
      }
  
}

#tc{
    margin-left: 0%;
}
.container{
   
    padding-top: 2%;
}
ul{
    padding: 0px;
}
.nav-item{
    padding: 0px;
    display: inline-flex;
    
  
    
}
.dropdown-item:focus, .dropdown-item:hover{
    background-color: transparent;
}
.dropdown-item:hover{
    background-color: transparent;
}
.dropdown-item{
    padding:0px ;
}
.col-9{
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

  @media only screen and(max-width:350px) {
    .container{
      
        padding-top: 3%;
    }
  }

  .sear{
    position: absolute;
 
    left: 10px;

  }
  @media (max-width:554px) and (max-width:560px) {
    .x::-webkit-input-placeholder {
       /* WebKit browsers */
        color: transparent;
   }
    .x:-moz-placeholder {
       /* Mozilla Firefox 4 to 18 */
        color: transparent;
   }
    .x::-moz-placeholder {
       /* Mozilla Firefox 19+ */
        color: transparent;
   }
   .x :-ms-input-placeholder {
       /* Internet Explorer 10+ */
        color: transparent;
   }
    .x::placeholder {
        color: transparent;
   }
    textarea::-webkit-input-placeholder {
       /* WebKit browsers */
        color: transparent;
   }
    textarea:-moz-placeholder {
       /* Mozilla Firefox 4 to 18 */
        color: transparent;
   }
    textarea::-moz-placeholder {
       /* Mozilla Firefox 19+ */
        color: transparent;
   }
    textarea:-ms-input-placeholder {
       /* Internet Explorer 10+ */
        color: transparent;
   }
    textarea::placeholder {
        color: transparent;
   }
}
#tex>svg{
    width:4vw;
}
#tc{
    padding: 0px;
}
body{
    background-color: #F5F5F5;
}

#tex{
    display:inline-flex;
    place-content: center;
    padding-top: 5px;
}
@media (max-width:717px) and (max-width:720px) {
   .nav-item {
    display: inline-flex;
   }
   #filt{
       position: absolute;
   }
}
@media (max-width:888px) and (max-width:889px) {
    .navsd {
     display: block;
    }
 
 }
html,body{
    margin:0;
    padding:0;
    width:100%;
    height:100%;
    background-color: #F5F5F5;
}
.filter{
    transition: all .5s ease-in-out; 
    
 display:none;
}
.filter1{
    transition: all .2s ease-in-out; 
 display:none;
}
#txa:hover  ~ .filter{

    display:flex;
}
.but:hover.svgg{

    display:block;
}
.but:active .svgg{

    display:block;
}
.but .svgg{

    display:none;
}
.svgg{
    position: absolute;
    top: 80%;
    margin-right: 10px;
    z-index: 100;
}
.svgg >path{
fill:#f92727;
}
.filter{
   
}
.filter1{
    margin-top: 8px;
}
.imgcol{
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    max-width: 790px;
    flex-wrap: nowrap;
   
    

}
.abso{
    width: 3vw;
    position: absolute;
    bottom: 22px;
    left: 55%;

}
.imog{    border-radius: 15px;
    
    box-shadow: inset rgb(255 255 255 / 41%) 0px 7px 5px 0px;
    
    
    border: 0.5px solid #FFFFFF ;
width: 100%;
background-repeat: no-repeat;
background-size: 100% 100%;
background-position: center top;
background-attachment: local;
max-width: 760px;
height: 100%;
max-height: 211px;
}
.nav-Item::-webkit-scrollbar {
    width: 1px;
    height:0px;
  }
  
  /* Track */
  .nav-Item::-webkit-scrollbar-track {
    
    
  }
   
  /* Handle */
  .nav-Item::-webkit-scrollbar-thumb {
    background: gray; 
  
  }
  
  /* Handle on hover */
  .nav-Item::-webkit-scrollbar-thumb:hover {
    background: rgb(58, 57, 57); 
  }
*::-webkit-scrollbar {
    width: 1px;
    height:0px;
  }
  
  /* Track */
*::-webkit-scrollbar-track {
    
    border-radius: 10px;
  }
   
  /* Handle */
*::-webkit-scrollbar-thumb {
    background: gray; 
  
  }
  
  /* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
    background: black; 
  }
  .col-8{
display: inline-flex;
justify-content: center;
  }
  .row{
    background-color: #fff;
  }
#stor{
    display: inline-flex;
    place-content: center;
    align-items: center;
    align-content: center;
    text-transform: none;
    justify-content: center;
    font-weight: normal;
    flex-direction: row;
    width: 100%;
}
#t{
    justify-content: space-between;
}
#t {
    position: relative;

  }
  
  .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFFFFF;
    filter: blur(12px);
    z-index: 0;
  }
  .disableBlur{
      z-index: 1;
  }
  body::-webkit-scrollbar {
    width: 10px;
    height:0px;
  }
  
  /* Track */
body::-webkit-scrollbar-track {
    
    
  }
   
  /* Handle */
*::-webkit-scrollbar-thumb {
    background: rgb(128, 128, 128); 
  
  }
  
  /* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
    background: rgb(73, 73, 73); 
  }
  .but >span{
      display: inline-flex;
      align-items: center;
      justify-content: center;
  }

  .but >span svg{
  margin-left: 1px;
   
}
.mpad{
    padding: 7px;
    height: 13.4vw;
    width: 100%;
    max-height: 180px;
    min-height: 60px;
}
#txa .sbut{
    height: 70px;
    width: 82px;
}

#txa .sbut >button{
width: 100%;
height: 100%;
}
#txa>.sbut >button>span:first-child{
    width: 100%;
    height: 100%;
    }
    #txa >.sbut >button{
        width: 100%;
        height: 100%;
        }
        #filt span #tex1{
           width: fit-content; 
        }
        .draged{
            display: inline-flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 10px;
        }
        .parents{
            position: relative;
            height: 100%;
            border-radius: 15px;
            box-shadow: rgb(0 0 0 / 16%) 0px 2px 4px  ;
            width: 100%;
            z-index: 1;
            max-width: 760px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }
        #lscr{
            background-color: transparent;
            border: 0px;
            box-shadow: none;
        }
        #rscr{
            background-color: transparent;
            border: 0px;
            box-shadow: none;
        }
        #lscr::active{
            background-color: transparent;
            border: 0px;
            box-shadow: none;
        }
        #rscr::active{
            background-color: transparent;
            border: 0px;
            box-shadow: none;
        }
        #lscr::hover{
            background-color: transparent;
            border: 0px;
            box-shadow: none;
        }
        #rscr::hover{
            background-color: transparent;
            border: 0px;
            box-shadow: none;
        }
        #lscr::focus{
            background-color: transparent;
            border: 0px;
            box-shadow: none;
        }
        #rscr::focus{
            background-color: transparent;
            border: 0px;
            box-shadow: none;
        }
        
      
         .but >span.svgg svg{
  margin-left: 1px;
      margin-top: 12px;
}